import React, { useCallback, useContext, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { PageContext } from '../../Context/PageProvider';
import { AppBar, Button, Typography, LinearProgress } from '@material-ui/core';
import Toolbar from '../../Components/Common/Toolbar';
import AlertDialog from '../../Components/Common/AlertDialog';
import DeviceListView from '../../Components/DeviceManagement/DeviceListView';
import Device from '../../m2m-cloud-api/Api/DeviceService/Models/Device';
import { mapErrorMessage } from '../../Utilities/ApiHelper';

import ArrowBack from '@material-ui/icons/ArrowBack';
import SearchDevicesForm from '../../Components/DeviceManagement/SearchDevicesForm';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyIcon from '@material-ui/icons/FileCopy';
import { uniqueStrings } from '../../Utilities/ArrayUtils';

const maxWidth = 760;

const DEFAULT_DEVICE_TYPE = 'all'

const DEFAULT_PAGE_SIZE = 1000;

const DeviceManagement = () => {
  const context = useContext(PageContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState({ driver: DEFAULT_DEVICE_TYPE, physicalIds: '' });
  const [alertDialog, setAlertDialog] = useState(null);
  const [loading, setLoading] = useState(false);
  const [orgs, setOrgs] = useState([]);
  const [devices, setDevices] = useState(null);
  const searchAfterHistory = useMemo(() => new Map(), []);

  const loadDevices = async (_page, pageSize = DEFAULT_PAGE_SIZE) => {
    try {
      setLoading(true);

      const searchParams = {};
      if (formValues.physicalIds) {
        let physicalIds = [];
        if (formValues.physicalIds.indexOf('\n') !== -1) {
          physicalIds = formValues.physicalIds.split('\n');
        } else if (formValues.physicalIds.indexOf(';') !== -1) {
          physicalIds = formValues.physicalIds.split(';');
        } else if (formValues.physicalIds.indexOf(',') !== -1) {
          physicalIds = formValues.physicalIds.split(',');
        } else if (formValues.physicalIds.indexOf(' ') !== -1) {
          physicalIds = formValues.physicalIds.split(' ');
        } else if (formValues.physicalIds.trim() !== -1) {
          physicalIds = [formValues.physicalIds];
        }
        physicalIds = physicalIds.map(physicalId => physicalId.trim());
        physicalIds = uniqueStrings(physicalIds.filter(physicalId => physicalId !== ''));
        if (physicalIds.length > 0) {
          searchParams['physicalIds'] = physicalIds;
        }
      }
      if (formValues.driver !== 'all') {
        searchParams['driver'] = formValues.driver;
      }
      if (formValues.type?.trim() !== '') {
        searchParams['type'] = formValues.type?.trim();
      }
      if (formValues.ownerOrg) {
        searchParams['ownerOrg'] = formValues.ownerOrg;
      }
      if (formValues.assignedOrg) {
        searchParams['assignedOrg'] = formValues.assignedOrg;
      }

      let devices = await context.deviceService.locateDevices(searchParams, searchAfterHistory.get(_page - 1), pageSize);
      if (devices?.items?.length > 0) {
        searchAfterHistory.set(_page, devices.searchAfter);
        devices.items = devices.items.map(item => new Device({ id: item.deviceId, physicalId: item.physicalId, ownerOrg: item.ownerOrg, assignedOrg: item.assignedOrg }));
      }
      const deviceIdsToLoad = devices.items?.length > 0 ? uniqueStrings(devices.items.map(device => device.getId())) : [];
      const accessibleDevices = await context.deviceService.getDeviceMultiple(deviceIdsToLoad);
      if (accessibleDevices?.length > 0) {
        for (let i = 0; i < accessibleDevices.length; i++) {
          const accessibleDevice = accessibleDevices[i];
          const locatedDeviceIndex = devices.items.findIndex(device => device.getId() === accessibleDevice.getId());
          devices.items[locatedDeviceIndex] = accessibleDevice;
          devices.items[locatedDeviceIndex].loaded = true;
        }
      }
      const orgIdsToLoad = [];
      if (devices?.items.length > 0) {
        for (let i = 0; i < devices.items.length; i++) {
          const device = devices.items[i];
          if (device.getAssignedOrg() && !orgs.find(org => org.getId() === device.getAssignedOrg())) {
            orgIdsToLoad.push(device.getAssignedOrg());
          }
          if (device.getOwnerOrg() && !orgs.find(org => org.getId() === device.getOwnerOrg())) {
            orgIdsToLoad.push(device.getOwnerOrg());
          }
        }
      }
      if (orgIdsToLoad.length > 0) {
        const loadedOrgs = await context.orgService.readMultiple(uniqueStrings(orgIdsToLoad));
        setOrgs(prevState => [...prevState, ...loadedOrgs]);
      }
      setDevices(devices);
      setLoading(false);
      const searchPhysicalIds = searchParams['physicalIds']
      if (searchPhysicalIds?.length > 0) {
        const duplicatedSearchPhysicalIds = searchPhysicalIds.filter((id, index, array) => array.indexOf(id) !== index)
        const notFoundSearchPhysicalIds = searchPhysicalIds.filter(id => !devices.items.some(device => device.getPhysicalId().toString() === id.toString()))
        if (!!notFoundSearchPhysicalIds.length) {
          setAlertDialog({
            title: t('search_devices'),
            message: `${t('device_physical_ids_not_found', { count: notFoundSearchPhysicalIds.length })}\n${!!duplicatedSearchPhysicalIds.length ? t('device_physical_ids_duplicated', { count: duplicatedSearchPhysicalIds.length }) : ''}`,
            params: {
              duplicatedSearchPhysicalIds,
              notFoundSearchPhysicalIds
            }
          });
        }
      }
    } catch (error) {
      console.log('DeviceManagement locate devices error... ', error);
      setAlertDialog({
        title: t('error'),
        message: mapErrorMessage(error),
      });
      setLoading(false);
    }
  };

  const onValueChange = useCallback((key, value) => {
    setFormValues(prevValues => ({ ...prevValues, [key]: value }));
  }, []);

  const onSearch = useCallback(() => {
    loadDevices(0, DEFAULT_PAGE_SIZE);
  }, [formValues]);

  const loadPage = (page, pageSize) => {
    loadDevices(page, pageSize);
  };

  return (
    <div className={classes.root}>
      {loading && <LinearProgress className={classes.progress} />}
      <AppBar position="fixed" className={classes.appBar} color="inherit">
        <Toolbar title={t('device_management')} />
      </AppBar>
      <>
        {!loading && !devices && (
          <main className={classes.formContainer} height="100%">
            <div className={classes.formContent}>
              <Typography className={classes.title} variant="h4">
                {t('search_devices')}
              </Typography>
              <SearchDevicesForm values={formValues} onValueChange={onValueChange} onSubmit={onSearch} />
            </div>
          </main>
        )}
        {devices?.items && (
          <div className={classes.content}>
            <DeviceListView className={classes.list} devices={devices} orgs={orgs} loadPage={loadPage} loading={loading}>
              <div className={classes.deviceToolbar}>
                <div className={classes.deviceToolbarRow}>
                  <Button startIcon={<ArrowBack />} onClick={() => setDevices(null)}>
                    {t('back')}
                  </Button>
                  <Typography className={classes.deviceToolbarTitle} variant="h6">
                    {t('device_management_search_title', { count: devices.count, driver: formValues.driver === 'all' ? t('all') : formValues.driver.toUpperCase() })}
                  </Typography>
                </div>
              </div>
            </DeviceListView>
          </div>
        )}
      </>
      {alertDialog && (
        <AlertDialog
          open={alertDialog ? true : false}
          title={alertDialog?.title}
          message={alertDialog?.message}
          submitButtonTitle={t('ok')}
          onSubmit={() => setAlertDialog(null)}
        >
          {alertDialog.params?.notFoundSearchPhysicalIds?.length > 0 && (
            <CopyToClipboard text={alertDialog.params.notFoundSearchPhysicalIds.join('\n')} className={classes.marginBottomSmall}>
              <Button variant={'outlined'} startIcon={<CopyIcon />}>
                {t('copy_to_clipboard_not_found_physical_ids', { count: alertDialog.params.notFoundSearchPhysicalIds.length })}
              </Button>
            </CopyToClipboard>
          )}
          {alertDialog.params?.duplicatedSearchPhysicalIds?.length > 0 && (
            <CopyToClipboard text={alertDialog.params.duplicatedSearchPhysicalIds.join('\n')}>
              <Button variant={'outlined'} startIcon={<CopyIcon />}>
                {t('copy_to_clipboard_duplicated_physical_ids', { count: alertDialog.params.duplicatedSearchPhysicalIds.length })}
              </Button>
            </CopyToClipboard>
          )}
        </AlertDialog>
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    paddingTop: theme.spacing(1),
  },
  deviceToolbar: {},
  deviceToolbarRow: {
    display: 'flex',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    flexDirection: 'row',
    backgroundColor: '#333',
  },
  deviceToolbarTitle: {
    marginTop: theme.spacing(0.1),
    marginLeft: theme.spacing(2),
  },
  formContainer: {
    flex: 1,
    flexGrow: 1,
    maxWidth: maxWidth,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    height: '100%',
  },
  formContent: {
    width: '100%',
    padding: theme.spacing(3),
    backgroundColor: 'rgba(255,255,255,0.02)',
    borderRadius: 4,
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    paddingTop: 0,
    flexDirection: 'column',
    height: '100%',
  },
  progress: {
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 99999,
    left: 0,
  },
  marginBottomSmall: {
    marginBottom: theme.spacing(1.5)
  }
}));

export default DeviceManagement;
