/**
 * Returns a new array containing only the unique elements from the input array.
 *
 * This function removes duplicates from the provided string array and returns
 * a new array that contains only unique values. The order of elements is preserved
 * based on their first occurrence in the input array.
 *
 * @param {string[]} arr - The array of strings from which to remove duplicates.
 * @returns {string[]} A new array containing only unique elements from the input array.
 *
 * @example
 * const strings = ["apple", "banana", "apple", "orange", "banana", "grape"];
 * const uniqueStringsArray = uniqueStrings(strings);
 * console.log(uniqueStringsArray);
 * // Output: ["apple", "banana", "orange", "grape"]
 */
export function uniqueStrings(arr) {
  return Array.from(new Set(arr));
}